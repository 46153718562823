import React from "react"




function Contact() {
    return (
        <div className="pages">
<h2> CONTACT </h2><a href="mailto:annemariesauerbier@googlemail.com"> E-MAIL</a>
        </div>
    )
}

export default Contact