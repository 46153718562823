
import React from "react";




 function Footer(props) {
    return (
   <footer className={props.darkMode ? "dark": ""} >
   © 2022 AS development. All rights reserved.
</footer>
       
  
    )
  }
  

  export default Footer;