import image1 from "../src/Assets/1.jpg";
import image2 from "../src/Assets/2.jpg";
import image3 from "../src/Assets/3.jpg";
import image4 from "../src/Assets/4.jpg";
import image5 from "../src/Assets/5.jpg";
import gif6 from "../src/Assets/6.gif";
import image7 from "../src/Assets/7.jpg";
import image8 from "../src/Assets/8.jpg";
import image9 from "../src/Assets/9.jpg";
import image10 from "../src/Assets/10.jpg";
import image11 from "../src/Assets/11.jpg";

export default [image1,image2,image3,image4,image5,gif6,image7,image8,image9,image10,image11];
