import React from 'react';



function Privacy() {
    return (
<div className='pages'>
   <h2> PRIVACY</h2>

   <p>


   Die Nutzung dieser Website kann mit der Verarbeitung von personenbezogenen Daten verbunden sein. Damit diese Verarbeitungen für Sie nachvollziehbar sind, möchten wir Ihnen mit den folgenden Informationen einen Überblick zu diesen Verarbeitungen verschaffen. Um eine faire Verarbeitung zu gewährleisten, möchten wir Sie außerdem über Ihre Rechte nach der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG) informieren.
   Wenn Sie Fragen oder Anregungen zu diesen Informationen haben oder sich wegen der Geltendmachung Ihrer Rechte an uns wenden möchten, richten Sie Ihre Anfrage bitte an 
  
  
   AS


   </p>
</div>

    );
}
export default Privacy;