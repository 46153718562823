
import React from 'react';



function About () {
    return (
<div className="Pages">
   <h2> ABOUT</h2>
   <p>I design products for the fashion, music, culture and small 
    and large business sectors. I use my knowledge of 
    user experience and classic design methods and transform them 
    in a contemporary way, combining art, science and technology. 
    I am always looking for new adventures. Let's start one! I can 
    help you solve important business problems with assessment, 
    problem solving, design development.
 </p>
</div>
    );
}
export default About;
